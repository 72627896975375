import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { breakpoints, colors, button } from '../../styles/theme'

export const CallToAction = ({ heading, to, buttonText }) => (
  <Outer>
    <Inner>
      <Heading className="heading--h2">{heading}</Heading>
      {to && <Button to={to}>{buttonText}</Button>}
    </Inner>
  </Outer>
)

const Outer = styled.div`
  display: inline-block;
  background: ${colors.primary}e6;
  padding: 16px;
  color: ${colors.white};
  text-align: center;
  position: relative;
  z-index: 10;
`

const Inner = styled.div`
  border: 1px solid ${colors.midGrey}44;
  padding: 16px;
`

const Heading = styled.h3`
  @media (min-width: ${breakpoints.medium}px) {
    font-size: 3.4rem;
  }
  margin: 0;
`

const Button = styled(Link)`
  ${button.default};
  ${button.outline};
  margin-top: 12px;
  margin-bottom: 4px;
  color: ${colors.accent};
  border-color: ${colors.accent};
  border-width: 1px;
`
