import React from 'react'
import Seo from '../components/SEO'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import { colors, container } from '../styles/theme'
import PageBody from '../components/shared/page-body'
import Cta from '../components/shoes/cta'

const TestimonialsPage = () => (
  <Layout>
    <Seo title="Testimonials" pathname="/testimonials/" />
    <TestimonialsPageBody>
      <TitleContainer>
        <h1 className="heading--h1">Fox Tailoring Testimonials</h1>
      </TitleContainer>
      <Container>
        <TestimonialsGrid>
          <Testimonial name="Kevin Scott" company="Hot Radio">
            <p>
              They said this guy was good but l didn't realise how good, until
              now.
            </p>
            <p>
              I've never done SMART, but will from now on. About time I grew up!
            </p>
            <p>Well done John Parrett at Fox Tailoring.</p>
          </Testimonial>
          <Testimonial
            name="Darren Northeast"
            title="Operations Director"
            company="Darren Northeast PR"
          >
            <p>
              There is nothing better than a TAILORED SUIT and Fox Tailoring are
              among the best. I have a number of outfits from them and l always
              feel my most confident when wearing them.
            </p>
            <p>
              I am always amazed by the unique stylish, look that John creates.
              He knows how to create a look which will get heads turning and
              jaws dropping. Detail is a key factor and you will walk away with
              a ONE OFF.
            </p>
          </Testimonial>
          <Testimonial
            name="Paul Kinvig"
            title="COO"
            company="Bournemouth Town Centre BID"
          >
            <p>
              I just felt compelled to let you know how much l love your
              tailoring. Wore the complimenting waistcoat with the Blue Tweed
              jacket at a recent function. <em>Loved it.</em>
            </p>
            <p>
              You reall know what works for me. I am genuinely honoured to be a
              Brand Ambassador.
            </p>
          </Testimonial>
          <Testimonial name="Christian Lawson" title="Photographer">
            <p>
              It's safe to say I had an absolute first-class experience from the
              gentleman behind… FOX TAILORING
            </p>
            <p>
              Thank you John for your patience. I never realised how in depth
              your service is and l am really looking forward to the end product
              that l will wear with pride, in taking my business look to the
              next level.
            </p>
          </Testimonial>
        </TestimonialsGrid>
      </Container>
    </TestimonialsPageBody>
    <Cta />
  </Layout>
)

const Testimonial = ({ name, title, company, children }) => (
  <Blockquote vocab="https://schema.org/" property="review" typeof="Review">
    <QuoteText property="reviewBody">{children}</QuoteText>
    <CitationContainer>
      <Cite property="author" typeof="Person">
        <Avatar name={name} />
        <div>
          <div property="name">{name}</div>{' '}
          {(title || company) && (
            <div className="orgInfo">
              {title && <span property="jobTitle">{title}</span>}
              {title && company && ', '}
              {company && (
                <span property="worksFor">
                  <span property="name">{company}</span>
                </span>
              )}
            </div>
          )}
        </div>
      </Cite>
    </CitationContainer>
  </Blockquote>
)

export default TestimonialsPage

const Avatar = ({ name }) => {
  const initials = name
    .split(' ')
    .map((w) => w[0])
    .join('')
  return <AvatarDisc>{initials}</AvatarDisc>
}

const AvatarDisc = styled.div`
  border-radius: 9999px;
  background: ${colors.accent};
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  flex: 0 0 auto;
  margin-right: 16px;
`

const TestimonialsPageBody = styled(PageBody)`
  padding: 60px 0;
  background: linear-gradient(${colors.darkGrey}, #000);
  background-size: cover;
`

const TitleContainer = styled.div`
  ${container};
  color: ${colors.white};
  text-shadow: 0 2px 4px ${colors.black}55;
  text-align: center;
`

const Container = styled.div`
  ${container};
`

const TestimonialsGrid = styled.div`
  margin: 80px 0 24px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  justify-content: center;
  grid-gap: 96px;
`

const Blockquote = styled.blockquote`
  margin: 0;
  background: ${colors.white};
  color: ${colors.black};
  padding: 32px 48px;
  box-shadow: 0 4px 40px ${colors.black}33;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const QuoteText = styled.div`
  p {
    margin: 10px 0;
  }
`

const CitationContainer = styled.footer`
  margin-top: 16px;
`

const Cite = styled.cite`
  display: flex;
  align-items: center;

  .orgInfo {
    font-size: 0.85em;
  }
`
