import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Banner, CallToAction } from '../banner-cta'

const ShoesCallToAction = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/complete-tailoring-service-flatlay.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 900, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div style={{ background: '#000' }}>
      <Banner
        image={{
          ...hero.childImageSharp,
          alt: 'The complete tailoring service flatlay',
          style: {
            maxWidth: '1440px',
            minHeight: '400px',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          imgStyle: {
            filter: 'brightness(70%)',
          },
        }}
      >
        <CallToAction
          heading="The complete tailoring service"
          to="/contact"
          buttonText="Enquire online"
        />
      </Banner>
    </div>
  )
}

export default ShoesCallToAction
