import React from 'react'
import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const Banner = ({ image, children }) => (
  <Container>
    <Image {...image} />
    {children && <CallToActionWrapper>{children}</CallToActionWrapper>}
  </Container>
)

export { CallToAction } from './cta'

const Container = styled.section`
  position: relative;
`

const CallToActionWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
